<template>
  <b-card class="mb-0">
    <b-row>
      <b-col cols="12" lg="6" md="6" sm="12" xs="12"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0" />
      <b-col cols="12" lg="12" md="12" sm="12" xs="12">
        <div class="d-flex align-items-center justify-content-between mb-1">
          <b-col class="d-flex" cols="12" lg="4" md="2" sm="2" xs="4">
            <v-select :options="options" label="pagination" v-model="perPage" />
          </b-col>
          <div class="d-flex align-items-center justify-content-end">
            <b-col cols="12" lg="" md="12" sm="4" xs="12" style="width: 40vh;">
              <b-form-input v-model="search" type="search" placeholder="Search" />
            </b-col>
            <b-button variant="outline-primary" @click="$router.push('/projects/create')">
              + Project
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-table id="projectTable" responsive show-empty :items="filterProjects" :key="filterProjects.id" :fields="fields"
      select-mode="single" :per-page="perPage" ref="selectableTable" :current-page="currentPage">
      <template #cell(members)="data">
        <b-dropdown no-caret boundary=".table-container" variant="outline">
          <template #button-content>
            <b-row v-if="data.item.members.length === 1">
              <b-avatar v-for="item in data.item.members" :key="item.id" variant="primary" size="40" :src="item.avatar">
                <b-img v-if="item.avatar != null" fluid :src="item.avatar" />
                <div v-else>{{ item.name.substr(0, 1).toUpperCase() }}</div>
              </b-avatar>
            </b-row>
            <b-row v-else-if="data.item.members.length <= 3">
              <b-avatar-group size="40" variant="primary">
                <b-avatar v-for="item in data.item.members" :key="item.id" :src="item.avatar">
                  <b-img v-if="item.avatar != null" fluid :src="item.avatar" />
                  <div v-else>{{ item.name.substr(0, 1).toUpperCase() }}</div>
                </b-avatar>
              </b-avatar-group>
            </b-row>
            <b-row v-else>
              <b-avatar-group size="40" variant="primary">
                <b-avatar v-for="i in 3" :key="i">
                  <b-img v-if="data.item.members[i].avatar != null" fluid :src="data.item.members[i].avatar"></b-img>
                  <div v-else>
                    {{ data.item.members[i].name.substr(0, 1).toUpperCase() }}
                  </div>
                </b-avatar>
                <b-avatar :text="`+${data.item.members.length - 3}`">
                </b-avatar>
              </b-avatar-group>
            </b-row>
          </template>
          <div style="max-height: 170px; overflow-y: auto;">
            <b-dropdown-item v-for="item in data.item.members" :key="item.id">
              <b-avatar :src="item.avatar" variant="primary" size="30" class="mr-1">
                <b-img v-if="item.avatar !== null && item.avatar !== ''" fluid :src="item.avatar" />
                <div v-else>{{ item.name.substr(0, 1).toUpperCase() }}</div>
              </b-avatar>
              {{ item.name }}
            </b-dropdown-item>
          </div>

        </b-dropdown>
      </template>

      <!-- Status -->
      <template #cell(status)="data">
        <b-badge :variant="`light-${categoryStatus(data.item.status)}`">
          {{ resolveStatusText(data.item.status) }}
        </b-badge>
      </template>

      <template #cell(activities)="data">
        <div>{{ data.item.activities.length > 0 ? data.item.activities.length : '0' }}</div>
      </template>

      <!-- Actions -->
      <template #cell(actions)="row">
        <b-row>
          <b-col cols="2">
            <b-button class="p-0" v-b-tooltip.hover title="Details" variant="flat" size="sm" @click="getDetail(row.item)">
              <feather-icon icon="FileIcon" />
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button class="p-0" v-b-tooltip.hover title="Edit" variant="flat" size="sm" @click="clickEdit(row)">
              <feather-icon icon="EditIcon" />
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button class="p-0" v-b-tooltip.hover title="Delete" variant="flat" size="sm" @click="clickModal(row)">
              <feather-icon icon="TrashIcon" />
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-table>

    <!-- Pagination -->
    <b-row align-h="end">
      <b-col md="12" class="d-flex align-items-center justify-content-end">
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
          aria-controls="projectTable"></b-pagination>
      </b-col>
    </b-row>

  </b-card>
</template>
  
<script>
import {
  BCardTitle,
  BTable,
  BCard,
  BButton,
  BRow,
  BCol,
  BModal,
  BFormInput,
  BContainer,
  BPagination,
  BAvatar,
  BImg,
  BIconEye,
  BIconPen,
  BBadge,
  BAvatarGroup,
  BDropdown,
  BDropdownItem,
  VBTooltip
} from "bootstrap-vue";
import { projectsHeaders } from "../utils/headers";
import vSelect from 'vue-select'

export default {
  components: {
    BCardTitle,
    BCard,
    BTable,
    BButton,
    BRow,
    vSelect,
    BFormInput,
    BCol,
    BPagination,
    BModal,
    BContainer,
    BAvatar,
    BImg,
    BIconEye,
    BIconPen,
    BBadge,
    BAvatarGroup,
    BDropdown,
    BDropdownItem,
    VBTooltip
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      fields: projectsHeaders,
      search: "",
      perPage: 10,
      currentPage: 1,
      options: [
        10, 50, 100
      ],
    };
  },
  async created() {
    await this.refetchData();
  },
  computed: {
    currentUser() {
      return this.$store.state.usersConfig.currentUser;
    },

    projects() {
      return this.currentUser.roles[0].name === "HEAD_OF_ORGANIZATION" ?
        this.$store.getters.orgProjects(this.currentUser.id) :
        this.currentUser.roles[0].name === "HEAD_OF_DEPARTMENT" ?
          this.$store.state.projectsConfig.projects :
          this.$store.state.projectsConfig.projects;
    },

    filterProjects() {
      return this.projects.filter((project) => {
        return (
          project.title.toLowerCase().includes(this.search.toLowerCase()) ||
          project.status.toLowerCase().includes(this.search.toLowerCase())
        );
      })
    },
    rows() {
      return this.filterProjects.length
    }
  },
  methods: {
    categoryStatus(categ) {
      return categ === 'activated' ?
        'primary' : categ === 'finished' ?
          'success' : 'primary'
    },
    resolveStatusText(status) {
      return status === 'activated' ?
        'ACTIVATED' : status === 'finished' ?
          'FINISHED' : '-'
    },
    async refetchData() {
      await this.$store.dispatch("getProjects");
    },
    getDetail(item) {
      this.$router.push("/projects/" + item.id);
    },
    async deleteProject(id) {
      await this.$store.dispatch("deleteProject", id)
      await this.refetchData();
    },
    clickModal(row) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete that line.", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.deleteProject(row.item.id);
          }
        });
    },
    clickEdit(row) {
      this.$router.push("/projects/edit/" + row.item.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
  